@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

:root {
    --font-base: 'Space Grotesk', sans-serif;
    --color-text: #47211A;
    --color-highlight: #FFF2B5;
    --color-dark: #47211A;
    --color-grey: #F3F3F3;
}

* {
    font-family: var(--font-base);

}

.section-padding {
    margin: 50px 0;
}

@media(max-width: 768px) {
    .section-padding {
        margin: 50px 0;
    }  
}


h1,
h2,
h3,
h4 {
    color: var(--color-text);
    font-weight: 500;
}

h2 {

    font-size: 40px;
    line-height: 51px;
}

h3 {
    font-size: 30px;
    line-height: 38px;
}

h4 {
    font-size: 20px;
    line-height: 26px;
}

.title {
    font-size: 60px;
    line-height: 77px;
}

@media(max-width: 768px) {
    .title {
        font-size: 43px;
        line-height: 55px;
    }
}


ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

input[type="email"],
input[type="text"] {
    border-radius: 14px;
    padding: 15px;
}

.btn-positivus {
    padding: 15px 35px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    /* background: #191A23; */
    border-radius: 14px;
    border: none;
    color: #fff;

}

.btn-positivus:hover {
    transform: scale(1.1);
}

@media(max-width: 768px) {
    .btn-positivus {
        padding: 15px;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
    }
}

p {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
}
.logo img {
    width: 200px; /* Adjust the width to the desired size */
    height: auto; /* Maintain the aspect ratio */
}
.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 1344px; /* You can adjust this value based on your design */
}
/* Home.css */

.telegram-float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #0088cc;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: heartbeat 1.5s infinite; /* Add heartbeat animation */
  }
  
  .telegram-float:hover {
    background-color: #007bb5;
  }
  
  @keyframes heartbeat {
    0%, 20%, 40%, 60%, 80%, 100% {
      transform: scale(1);
    }
    10%, 30%, 50%, 70%, 90% {
      transform: scale(1.1);
    }
  }
  