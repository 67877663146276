

@media(max-width: 1024px) {
    .clients{
        justify-content: flex-start;
    }

    
}

