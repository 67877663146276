.cta{
background: #F3F3F3;
border-radius: 45px;
position: relative;
padding:4rem  3rem;
margin: 6rem 0;
}

.cta img{
margin-top: -100px;
    position: absolute;
}
.cta-image {
    width: 100%; /* Makes the image scale to the width of its container */
    height: auto; /* Maintains the aspect ratio of the image */
    max-width: 100%; /* Ensures the image doesn't exceed its container's width */
    display: block; /* Removes any extra space below the image */
    margin: 0 auto; /* Centers the image horizontally within its container */
    border-radius: 45px;
  }
@media(max-width: 960px) {
    
    .cta{
        padding:2rem;
        text-align: center;
        margin: 4rem 1rem;
        }


}
