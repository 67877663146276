.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1140px; /* You can adjust this value based on your design */
}
.previous-event-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.previous-event-header{
    background:#FFF2B5;
    width: 30rem;
    color:#721c24;

	
}

.previous-event-col-lg-4, 
.previous-event-col-md-6, 
.previous-event-col-sm-12 {
    padding-right: 15px;
    padding-left: 15px;
}

.previous-event-header {
    margin-bottom: 3rem;
    align-items: center;
}

/* Optional: For extra large screens, you can add more spacing */
@media (min-width: 1200px) {
    .container {
        padding-left: 30px;
        padding-right: 30px;
    }
}


@keyframes previousEventFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes previousEventFadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
