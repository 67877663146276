.hero {
    margin-top: 1px;
    padding-left: 20px; /* Adjust to match your navbar padding */
    padding-right: 20px; /* Adjust to match your design requirements */
}

.img-fluid {
    margin-left: 0; /* Remove fixed margin for better responsiveness */
    width: 100%; /* Ensure the image scales with its container */
    max-width: 600px; /* Optional: Set a maximum width for larger screens */
}

/* Ensure images in the clients section are responsive */
.clients {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0 10px; /* Optional: Add padding for better spacing */
}

.clients img {
    filter: grayscale(100%);
    cursor: pointer;
    padding: .5rem;
    margin: 1rem; /* Adjust margin for responsiveness */
    max-width: 100%; /* Ensure images are responsive */
}

.clients img:hover {
    filter: grayscale(0);
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .hero {
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .img-fluid {
        margin-left: 0; /* Remove margin for smaller screens */
        max-width: 100%; /* Ensure image fits smaller screens */
    }

    .clients {
        padding: 0 5px; /* Reduce padding for smaller screens */
    }

    .clients img {
        margin: 0.5rem; /* Adjust margin for smaller screens */
    }
}
.container2 {
    margin-left: auto;
    margin-right: auto;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 1200px; /* You can adjust this value based on your design */
}