#partners {
  position: relative;
  padding: 2rem 0; /* Optional: Add padding around the section */
}

.partners-wrapper {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the container horizontally */
  position: relative;
}

.partners-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 3rem; /* Reduced space between logos for better responsiveness */
  padding: 1rem;
  justify-content: center; /* Center the items within the container */
}

.partner-item {
  flex: 0 0 auto;
  width: 120px; /* Adjust width for smaller devices */
}

.partner-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.partner-item img:hover {
  transform: scale(1.05);
}

.scroll-btn {
  display: none; /* Hide buttons by default on smaller screens */
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  transition: background 0.3s ease;
}

.scroll-btn:hover {
  background: #f0f0f0;
}

.scroll-btn.prev {
  left: 0;
}

.scroll-btn.next {
  right: 0;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .partner-item {
      width: 150px; /* Increase width for medium screens */
  }
}

@media (min-width: 1024px) {
  .scroll-btn {
      display: block; /* Show scroll buttons on larger screens */
  }

  .partners-container {
      gap: 5rem; /* Increase gap between logos for larger screens */
  }
}
