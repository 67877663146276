.registration-container {
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.registration-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.registration-container .btn {
  width: 30%;
  padding: 10px;
  background-color: #721c24; /* Green color */
  margin-left: 10rem;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
}

.registration-container .btn:hover {
  background-color: #721c25ab; /* Darker green on hover */
}


.success-message {
  margin-top: 15px;
  padding: 10px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  border-radius: 5px;
}

.error-message {
  margin-top: 15px;
  padding: 10px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border-radius: 5px;
}
