/* Checkout.css */

.checkout-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.checkout-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.checkout-form .form-group {
  margin-bottom: 20px;
}

.payment-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.payment-option {
  display: flex;
  align-items: center;
}

.payment-option input[type="radio"] {
  margin-right: 8px;
}

.payment-option label {
  font-size: 1rem;
  color: #555;
  cursor: pointer;
}

.checkout-button {
  display: block;
  width: 30%;
  padding: 10px;
  margin-left: 130px;
  background-color: #41211A;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-items: center;
}

.checkout-button:hover {
  background-color: #41211A;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.overlay-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  position: relative;
  width: 90%;
  max-width: 500px;
  text-align: center;
}

.close-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

.payment-overlay h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.payment-overlay p {
  margin-bottom: 20px;
  font-size: 1rem;
  color: #555;
}

.payment-overlay ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.payment-overlay ul li {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #555;
}

.proceed-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #41211A;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.proceed-button:hover {
  background-color: #41211A;
}
