h1, h2, h3, h4, h5, h6 {
    color: #2c3145;
}

a, a:hover, a:focus, a:active {
    text-decoration: none;
    outline: none;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.section_all {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
    min-height: 100vh;
}

.section_title_all {
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 1px;
}

.section_subtitle {
    letter-spacing: 0.4px;
    line-height: 28px;
    max-width: 550px;
}

.section_title_border {
    background-color: #000;
    height: 3px;
    width: 44px;
}

.section_title_border_white {
    background-color: #fff;
    height: 2px;
    width: 100px;
}

.text_custom {
    color: #47211A;
}

.about_icon i {
    font-size: 22px;
    height: 65px;
    width: 65px;
    line-height: 65px;
    display: inline-block;
    background: #fff;
    border-radius: 35px;
    color: #41211A;
    box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
}

.about_header_main .about_heading {
    max-width: 450px;
    font-size: 24px;
}

.about_icon span {
    position: relative;
    top: -10px;
}

.about_content_box_all {
    padding: 28px;
}

