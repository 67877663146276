#partners {
    position: relative;
    padding: 2rem 0; /* Padding around the section */
}

.partners-wrapper {
    display: flex;
    align-items: center;
    justify-content: center; /* Center horizontally */
    position: relative;
    overflow: hidden; /* Hide overflow to handle scrolling */
    height: 400px; /* Adjust height as needed */
}

.partners-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    height: 100%; /* Full height of the container */
    overflow-y: auto; /* Enable vertical scrolling */
    scroll-behavior: smooth; /* Smooth scrolling */
    gap: 1rem; /* Space between logos */
    padding: 1rem;
    justify-content: center; /* Center items vertically */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.partner-item {
    flex: 0 0 auto;
    width: 100px; /* Default width for small screens */
    height: 100px; /* Default height for small screens */
    margin-top: 20px; /* Increase top margin */
}

.partner-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.partner-item img:hover {
    transform: scale(1.05);
}

.scroll-btn {
    display: none; /* Hide buttons by default */
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 50%;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    transition: background 0.3s ease;
}

.scroll-btn:hover {
    background: #f0f0f0;
}

.scroll-btn.prev {
    left: 10px; /* Adjust as needed */
}

.scroll-btn.next {
    right: 10px; /* Adjust as needed */
}

/* Responsive adjustments */
@media (min-width: 768px) {
    .partners-wrapper {
        height: auto; /* Adjust height for medium screens */
    }

    .partners-container {
        flex-direction: row; /* Stack items horizontally on larger screens */
        overflow-x: auto; /* Enable horizontal scrolling on larger screens */
        gap: 2rem; /* Space between logos */
    }

    .partner-item {
        width: 150px; /* Increase width for larger screens */
        height: auto; /* Adjust height for larger screens */
        margin-top: 30px; /* Increase top margin for larger screens */
    }

    .scroll-btn {
        display: block; /* Show scroll buttons on larger screens */
    }
}
